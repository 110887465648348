<template>
    <el-dialog title="温馨提示" :close-on-click-modal="false" :visible.sync="isShowInvite" top="35vh" width="30%"
        :before-close="cancelInviteMethod" custom-class="inviteDialog" :show-close="false" destroy-on-close>
        <div class="inviteContent">
            <!-- 要动态变 -->
            <p>{{ currentAgentSet.set_invitation_code_tips }}</p>
            <el-input v-model="inviteCode" placeholder="请填写6位数邀请码"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="bindInviteMethod">确 定</el-button>
                <el-button @click="changeMenu" v-if="currentAgentSet.is_force_set_invitation_code == '1'">退出软件
                </el-button>
                <el-button @click="isShowInvite = false" v-else>取消</el-button>
            </span>
        </div>
    </el-dialog>
</template>

<script>
  import { MessageBox, Message, Loading } from "element-ui";
 import api from "../../http/api";
import { mapState } from "vuex";
export default {
    props: [''],
    data() {
        return {
            inviteCode:'',
            // agentList: {},//代理商信息
            isShowInvite: false,
            currentAgentSet:'',//代理商信息
        }
    },
    computed: {
        //...mapState(['currentAgentSet']), //
    },
    components: {

    },
    created() {
     //console.log('currentAgentSet????/--->',this.currentAgentSet)
    },
    methods: {
        //退出软件
        changeMenu() {
            this.$emit('exitSoftware', true)
        },
        bindInviteMethod() {
            let that = this;
            if (!that.inviteCode || (that.inviteCode && !that.inviteCode.trim())) {
                Message({
                    message: '请输入邀请码~',
                    type: 'error'
                });
                return;
            }
            api.bindInviteCode({
                invite_code: that.inviteCode
            }).then(res => {
                console.log('bindInviteCode-->', res)
                if (res.code == 200) {
                    that.inviteCode = '';
                    that.isShowInvite = false;
                    that.$emit('sureInvite', true)
                   // that.getInfo(); //重新获取用户信息
                } else {
                    Message({
                        message: res.msg,
                        type: 'error'
                    });
                }
            })
        },
        cancelInviteMethod() {
            this.isShowInvite = false;
            this.$emit('cancleInvite', true)
        },
    }
}
</script>
<style lang="less" scoped>
     /deep/ .inviteDialog .el-dialog__body {
    padding: 0px 20px 20px 20px;
  }

  /deep/ .inviteDialog .el-dialog__header {
    text-align: center;
  }

  .inviteDialog .dialog-footer {
    text-align: center;
    display: block;
    margin-top: 20px;
  }

  .inviteDialog .el-button {

    padding: 10px 20px;

  }
  .inviteContent {
    width: 100%;

  }

  .inviteContent p {
    color: red;
    margin: 10px 0;
  }
</style>
